import { Controller } from "@hotwired/stimulus";
import { Book } from "../models/book";
import { Serializer } from "../state/serializer";
import { AppBooks } from "../state/books";

export class BooksController extends Controller {

    initialize(): void {
        this.#hydrate();
    }

    toggleExpand(event) {
        const bookElement = this.#findBookElement(event.target);

        this.#toggleCSSClass(bookElement, 'expanded');
    }

    toggleOwned(event) {
        this.#toggleBookPropertyFromElement(event.target, 'owned');
        this.#toggleCSSClass(event.currentTarget, 'active');
    }

    toggleRead(event) {
        this.#toggleBookPropertyFromElement(event.target, 'read');
        this.#toggleCSSClass(event.currentTarget, 'active');

        // update the cover element's 'read' css variable
        // TODO: find a way to do this only when view === tiles?
        const bookElement = this.#findBookElement(event.target);
        const read = this.#getBookPropertyFromElement(bookElement, 'read');
        const coverElement = bookElement.querySelector<HTMLElement>('div.cover');
        if (coverElement) {
            coverElement.style.setProperty('--read', read ? '1' : '0');
        }
    }

    #toggleCSSClass(element, className) {
        if (element) {
            if (!element.classList.contains(className)) {
                element.classList.add(className);
            } else {
                element.classList.remove(className);
            }
        }
    }

    #getBookPropertyFromElement(element: HTMLElement, property: string) {
        const guid = this.#getBookGuid(element);
        if (guid) {
            const book: Book = AppBooks.getBook(guid);
            return book[property];
        }
    }

    #toggleBookPropertyFromElement(element: HTMLElement, property: string) {
        const guid = this.#getBookGuid(element);

        if (guid) {
            this.#toggleBookProperty(guid, property);
            this.#persist();
        }
    }

    #toggleBookProperty(bookGuid: string, property: string) {
        const book: Book = AppBooks.getBook(bookGuid);
        book[property] = !book[property];
    }

    #findBookElement(element: HTMLElement) {
        let current = element;

        while(current !== null && current.classList.contains('book') === false) {
            current = current.parentElement;
        }

        return current;
    }

    #getBookGuid(element: HTMLElement) {
        const bookElement = this.#findBookElement(element);
        return bookElement?.dataset.guid;
    }

    #persist() {
        const data = AppBooks.getAllBooks()
                                .filter(x => x.owned || x.read)
                                .map(book => {
                                    return {
                                        guid: book.guid,
                                        owned: book.owned,
                                        read: book.read
                                    };
                                });
        Serializer.save("book-user-data", data);
    }

    #hydrate() {
        const data = Serializer.load<any[]>("book-user-data") || [];
        data.forEach(b => {
            const book = AppBooks.getBook(b.guid);
            if (book) {
                book.owned = b.owned;
                book.read = b.read;
            }
        });
    }
}