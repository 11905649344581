import { Controller } from "@hotwired/stimulus";
import { Serializer } from "../state/serializer";
import { AppBooks } from "../state/books";
import { Renderer } from "../templates";
import sortAsc from "data-url:../../images/sort-asc.svg";
import sortDesc from "data-url:../../images/sort-desc.svg";

export class SortController extends Controller {
    static targets = [
        "sortImage",
        "radioIndex",
        "radioTitle",
    ]

    declare sortImageTarget: HTMLImageElement;
    declare radioIndexTarget: HTMLInputElement;
    declare radioTitleTarget: HTMLInputElement;

    initialize(): void {
        this.#hydrate();
        this.sortImageTarget.src = this.#getSortDirectionImage();
        const sorting = AppBooks.getSort();
        this.radioIndexTarget.checked = sorting.field === 'index';
        this.radioTitleTarget.checked = sorting.field === 'title';
    }

    invert(event) {
        AppBooks.setSortDirection(!AppBooks.getSort().asc);

        event.currentTarget.src = this.#getSortDirectionImage();

        AppBooks.sort();
        Renderer.renderList();

        this.#persist();
    }

    setField(event) {
        AppBooks.setSortField(event.target.value);
        AppBooks.sort();
        Renderer.renderList();

        this.#persist();
    }

    reset(event) {
        // Note: we don't handle resetting the sorting here, it's handled
        // in the filter controller (it saves us a render).
        // We'll revisit this if one day we want to separately clear the filters
        // and reset the sorting.

        this.radioIndexTarget.checked = true;
        this.sortImageTarget.src = this.#getSortDirectionImage();
    }

    #getSortDirectionImage(): string {
        return AppBooks.getSort().asc ? sortAsc : sortDesc;
    }

    #persist() {
        const data = AppBooks.getSort();
        Serializer.save("sorting", data);
    }

    #hydrate() {
        const data: any = Serializer.load<any>("sorting");
        if (data) {
            AppBooks.setSortField(data.field);
            AppBooks.setSortDirection(data.asc);
        }
    }
}

