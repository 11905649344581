import { Filterer } from "./filterer";
import { Sorter } from "./sorter";
import { normalizeString } from "../utils";
import { Serializer } from "./serializer";
import { Book } from "../models/book";

export class Books {
    #books: Book[];
    #filteredBooks: Book[];
    #sorter: Sorter = new Sorter();
    #filterer: Filterer = new Filterer();

    async loadBooks() {
        await this.#refreshBooks();
        this.#books = Serializer.load<{ books: Book[] }>('all-books')?.books;
    }

    async #refreshBooks() {
        const localDataDate = Serializer.load<number>('all-books-date') || 0;

        // get headers so we can read the Last-Modified date
        let response = await fetch('/data.json', { method: 'HEAD' });
        const lastModified = new Date(response.headers.get('Last-Modified')).getTime();

        // if Last-Modified date is newer than that of our local data, update it
        if (lastModified > localDataDate) {
            response = await fetch('/data.json');
            const books: { books: Book[] } = await response.json();
            Serializer.save('all-books', books);
            Serializer.save('all-books-date', lastModified);
        }
    }

    async bootstrap() {
        await this.loadBooks();
        this.#books.forEach(book => {
            book.normalizedTitle = normalizeString(book.title);
        });
        this.#filteredBooks = this.#books.slice();
    };

    filter() {
        this.#filteredBooks = this.#filterer.filter(this.#books);
        // always apply sort after filtering, since filtering
        // builds a new list from the unsorted full book list
        this.sort();
    }

    setFilter(field, value) {
        this.#filterer.setFilter(field, value);
    }

    getFilters() {
        return this.#filterer.filters;
    }

    sort() {
        this.#filteredBooks = this.#sorter.sort(this.#filteredBooks);
    }

    setSortField(field) {
        this.#sorter.field = field;
    }

    setSortDirection(asc: boolean) {
        this.#sorter.asc = asc;
    }

    getSort() {
        return {
            field: this.#sorter.field,
            asc: this.#sorter.asc
        }
    }

    getBooks(): Book[] { return this.#filteredBooks; }

    getAllBooks(): Book[] { return this.#books; }

    getBook(guid: string): Book { return this.#books.find(x => x.guid === guid); }

    clearFilteringAndSorting() {
        this.#filterer.clear();
        this.filter();
        this.#sorter.clear();
        this.sort();
    }
}

export const AppBooks = new Books();

export const bootstrapState = async function () {
    await AppBooks.bootstrap();
}
