import { Controller } from "@hotwired/stimulus";
import { Serializer } from "../state/serializer";
import { Renderer } from "../templates";

export class ViewsController extends Controller {
    #currentView: string = 'tiles';

    initialize(): void {
        this.#hydrate();
        Renderer.setCurrentTemplate(this.#currentView);
    }

    switch(event) {
        const newView = event.currentTarget.dataset.view;

        if (newView !== this.#currentView) {
            this.#currentView = newView;

            Renderer.renderList(this.#currentView);

            this.#persist();
        }
    }

    #persist() {
        Serializer.save("view", this.#currentView);
    }

    #hydrate() {
        const data: any = Serializer.load<any>("view");
        if (data) {
            this.#currentView = data;
        }
    }
}
