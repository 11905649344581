import { Controller } from "@hotwired/stimulus";
import { AppBooks } from "../state/books";
import { Renderer } from "../templates";
import { debounce } from "../utils";
import { Serializer } from "../state/serializer";
import { Filter } from "../state/filterer";

export class FilterController extends Controller {
    static targets = [
        "title",
        "countAll",
        "countFiltered",
        "options",
        "radioRead",
        "radioReadTrue",
        "radioReadFalse",
        "radioOwned",
        "radioOwnedTrue",
        "radioOwnedFalse",
    ];

    declare titleTarget: HTMLInputElement;
    declare countAllTarget: HTMLElement;
    declare countFilteredTarget: HTMLElement;
    declare optionsTarget: HTMLElement;
    declare radioReadTarget: HTMLInputElement;
    declare radioReadTrueTarget: HTMLInputElement;
    declare radioReadFalseTarget: HTMLInputElement;
    declare radioOwnedTarget: HTMLInputElement;
    declare radioOwnedTrueTarget: HTMLInputElement;
    declare radioOwnedFalseTarget: HTMLInputElement;

    initialize(): void {
        this.#hydrate();

        // these will be called from the main app after we're sure
        // everything is loaded and filters are restored from
        // local storage and applied
        // this.#renderCountAll();
        // this.#renderCountFiltered();

        const filters = AppBooks.getFilters();
        this.titleTarget.value = filters.find(x => x.field === 'normalizedTitle')?.value || '';

        const filterRead = filters.find(x => x.field === 'read');
        this.radioReadTarget.checked = !filterRead || filterRead.value === null;
        this.radioReadTrueTarget.checked = filterRead && filterRead.value === true;
        this.radioReadFalseTarget.checked = filterRead && filterRead.value === false;
        const filterOwned = filters.find(x => x.field === 'owned');
        this.radioOwnedTarget.checked = !filterOwned || filterOwned.value === null;
        this.radioOwnedTrueTarget.checked = filterOwned && filterOwned.value === true;
        this.radioOwnedFalseTarget.checked = filterOwned && filterOwned.value === false;
    }

    filterByOwned(event) {
        this.#setFilter("owned", event.target.value);
    }

    filterByRead(event) {
        this.#setFilter("read", event.target.value);
    }

    filterByTitle = debounce((event) => {
        this.#setFilter("normalizedTitle", event.target.value);
    }, 200);

    toggleOptions(event) {
        this.optionsTarget.hidden = !this.optionsTarget.hidden;
    }

    clear() {
        AppBooks.clearFilteringAndSorting();
        this.titleTarget.value = "";
        this.radioReadTarget.checked = true;
        this.radioOwnedTarget.checked = true;

        this.#renderCountFiltered();
        Renderer.renderList();

        this.#persist();
    }

    #setFilter(field, value) {
        AppBooks.setFilter(field, value);
        AppBooks.filter();

        this.#renderCountFiltered();

        Renderer.renderList();

        this.#persist();
    }

    renderCounters() {
        this.#renderCountAll();
        this.#renderCountFiltered();
    }

    #renderCountAll() {
        this.countAllTarget.innerHTML = AppBooks.getAllBooks().length.toString();
    }

    #renderCountFiltered() {
        this.countFilteredTarget.innerHTML = AppBooks.getBooks().length.toString();
    }

    #persist() {
        const data = AppBooks.getFilters();
        Serializer.save("filtering", data);
    }

    #hydrate() {
        const filters: Filter[] = Serializer.load<Filter[]>("filtering") || [];
        filters.forEach(f => {
            AppBooks.setFilter(f.field, f.value);
        });
    }
}
