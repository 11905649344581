import { Book } from "../models/book";
import { Template } from "../models/template";
import { Books } from "../state/books";
import { TemplatesRegistry } from "./template-registry";

export class TemplateRenderer {
    private currentTemplate: Template;
    private state: Books;
    private registry: TemplatesRegistry;

    initialize(state: Books, registry: TemplatesRegistry) {
        this.state = state;
        this.registry = registry;
    }

    // TODO: extract css selectors?

    setCurrentTemplate(template: string) {
        if (template) {
            this.currentTemplate = this.registry[template];
        }
    }

    renderList(template?: string) {
        this.setCurrentTemplate(template);
        const html = this.currentTemplate.wrapper(this.state.getBooks());
        const container = document.getElementById("books");
        container.className = this.currentTemplate.name;
        container.innerHTML = html;
    }

    renderBook(book: Book) {
        const html = this.currentTemplate.book(book);
        const template = document.createElement('template');
        template.innerHTML = html.trim();
        document.querySelector(`.book[data-guid="${book.guid}"]`).replaceWith(template.content.firstChild);
    }
}