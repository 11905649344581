import { Book } from "../models/book";
import { normalizeString } from '../utils';

export class Filter {
    constructor(field: string, value: any) {
        this.field = field;
        this.value = value;
    }

    field: string;
    value: any;
}

export class Filterer {
    filters: Filter[] = [];
    #fieldTypes = {
        normalizedTitle: 'string',
        owned: 'bool',
        read: 'bool',
    };
    #filterFuncs = {
        number: (x: Book, filter: Filter) => { return x[filter.field] === filter.value; },
        bool: (x: Book, filter: Filter) => { return !!x[filter.field] === filter.value; },
        string: (x: Book, filter: Filter) => { return x[filter.field] && x[filter.field].indexOf(filter.value) !== -1; },
    }

    #getTypedValue(value, field) {
        if (value === '') {
            return null;
        }
        switch (this.#fieldTypes[field]) {
            case 'string':
                return normalizeString(value);
            case 'number':
                if (typeof(value) === 'string') {
                    return parseInt(value, 10);
                } else {
                    return value;
                }
            case 'bool':
                if (typeof(value) === 'string') {
                    return value?.toLowerCase() === 'true';
                } else {
                    return value;
                }
            default:
                return value;
        }
    }

    #getFilterFunc(filter: Filter) {
        return this.#filterFuncs[this.#fieldTypes[filter.field]].bind(this);
    }

    setFilter(field: string, value: any) {
        const filterIndex = this.filters.findIndex(x => x.field === field);
        if (filterIndex !== -1) {
            if (value !== null) {
                const filter = this.filters.find(x => x.field === field);
                filter.value = this.#getTypedValue(value, field);
            } else {
                this.filters.splice(filterIndex, 1);
            }
        } else if (value !== null) {
            this.filters.push(new Filter(field, this.#getTypedValue(value, field)));
        }
    }

    filter(books: Book[]) {
        const funcs = this.filters.map(filter => { return { filter, func: this.#getFilterFunc(filter) } });
        return books.filter(book => {
            return funcs.every(func => func.filter.value === null || func.func(book, func.filter));
        });
    }

    clear() {
        this.filters = [];
    }
}