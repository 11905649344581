import { Book } from '../models/book';

export class Sorter {
    #collator: Intl.Collator = new Intl.Collator('fr');
    #fieldTypes = {
        index: 'number',
        title: 'string'
    };
    #comparers = {
        number: (a: Book, b: Book) => { return a[this.field] - b[this.field]; },
        string: (a: Book, b: Book) => { return this.#collator.compare(a[this.field], b[this.field]); },
    };
    #getComparer() {
        const comparer = this.#comparers[this.#fieldTypes[this.field]].bind(this);
        if (this.asc) {
            return comparer;
        } else {
            return (a, b) => comparer(a, b) * -1;
        }
    }

    field: string = 'index';
    asc: boolean = true;

    sort(books: Book[]) {
        return books.sort(this.#getComparer());
    }

    clear() {
        this.field = 'index';
        this.asc = true;
    }
}