import table from "./template.table";
import tiles from "./template.tiles";
import { TemplatesRegistry } from "./template-registry";
import { TemplateRenderer } from "./template-renderer";
import { Books } from "../state/books";

export const Renderer = new TemplateRenderer();

export const bootstrapTemplates = function (state: Books) {
    const registry = new TemplatesRegistry();
    registry.registerTemplate(table);
    registry.registerTemplate(tiles);

    Renderer.initialize(state, registry);
}