import { bootstrapControllers } from "./controllers";
import { bootstrapSerializer } from "./state/serializer";
import { AppBooks, bootstrapState, Books } from "./state/books";
import { bootstrapTemplates, Renderer } from "./templates/";

export class App {
    private state: Books;
    constructor() {
        this.state = AppBooks;
    }

    async bootstrap(window: Window) {
        bootstrapSerializer(window);
        await bootstrapState();
        bootstrapTemplates(this.state);
        bootstrapControllers(window);

        // we want to wait for every Stimulus controller
        // to be initialized before rendering the list
        // for the first time (so sorting and filtering read
        // from LocalStorage are applied), and apparently
        // waiting a tick is sufficient for that
        setTimeout(() => {
            AppBooks.filter();
            AppBooks.sort();

            // meh.
            // we can't call this from inside FilterController because it would be too soon,
            // not all the data needed to compute the counters would be loaded yet, so we do it here
            const filterController: any = window.Stimulus.getControllerForElementAndIdentifier(document.getElementById('menu'), 'filter');
            filterController.renderCounters();

            Renderer.renderList();
        });
    }

    /*
    async import() {
        const file = this.files[0];
        const csv = await file.text();
        let needsUpdate = false;

        const lines = csv.split('\n');
        let all = 0, notFound = 0;
        lines.forEach(x => {
            let title, read, owned;
            [title, read, owned] = x.split(';');
            if (title) {
                const normalizedTitle = this.state.filtering.normalizeString(title).replace(/[^a-z]/g, '');
                all++;
                read = read && (read.trim().toLowerCase() === 'vrai' || read === "1");
                owned = owned && (owned.trim().toLowerCase() === 'vrai' || owned === "1");
                const book = this.state.books.data.find(book => book.normalizedTitle.replace(/[^a-z]/g, '') === normalizedTitle);
                if (!book) {
                    notFound++;
                    console.log(title);
                } else {
                    needsUpdate = true;
                    this.setUserDataBookState(book.guid, 'owned', owned);
                    this.setUserDataBookState(book.guid, 'read', read);
                }
            }
        });
        if (needsUpdate) {
            this.state.api.save();
        }
        console.log(`${all - notFound}/${all} found`);
    }
    */
}
