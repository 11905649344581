import { Book } from "../models/book";
import { Template } from "../models/template";

const template: Template = new Template();
template.name = 'table';
template.book = (book: Book) => `
    <tr class="book" data-guid="${book.guid}">
        <td>#${book.index}</td>
        <td>${book.title}</td>
        <td>${book.publishedYear}</td>
        <td><button data-action="click->books#toggleOwned" class="${book.owned ? 'active' : ''}">Je l'ai</button></td>
        <td><button data-action="click->books#toggleRead" class="${book.read ? 'active' : ''}">Je l'ai lu</button></td>
    </tr>
`;
template.wrapper = (books: Book[]) => `
    <div>
        <table>
            <tr>
                <th>Numéro</th>
                <th>Titre</th>
                <th>Année de publication</th>
                <th>Je l'ai</th>
                <th>Je l'ai lu</th>
            </tr>
            ${ books.map(book => template.book(book)).join('') }
        </table>
    </div>
`;

export default template;