import { Template } from "../models/template";
import { Book } from "../models/book";

const template = new Template();
template.name = 'tiles';
template.book = (book: Book) => `
    <div class="book" data-guid="${book.guid}">
        <div data-action="click->books#toggleExpand" class="cover" style="--bookUrl: url(images/covers/${book.guid}.jpg); --read: ${book.read ? 1 : 0}"></div>
        <div class="info">
            <div class="header">
                <div class="index">#${book.index}</div>
                <div class="year">${book.publishedYear}</div>
            </div>
            <div class="title">${book.title}</div>
            <div class="summary">${book.summary}</div>
        </div>
        <div class="actions">
            <div data-action="click->books#toggleOwned" class="${book.owned ? 'active' : ''}">Je l'ai</div>
            <div data-action="click->books#toggleRead" class="${book.read ? 'active' : ''}">Je l'ai lu</div>
        </div>
    </div>`;
template.wrapper = (books: Book[]) => `${ books.map(book => template.book(book)).join('') }`;

export default template;
