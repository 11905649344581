export function debounce(func, wait, immediate: boolean = false) {
    let timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

export function normalizeString(str) {
    if (str === null) {
        return null;
    }
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
}
