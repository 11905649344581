class StateSerializer {
    #storage: Storage = null;
    // increment this when changing local storage's data structure
    // in an incompatible way, and provide a migration method (see #migrateData())
    #dataVersion: number = 1;
    #dataKey: string = "version";

    initialize(window: Window) {
        if (typeof(Storage) !== "undefined") {
            this.#storage = window.localStorage;

            this.#checkDataVersion();
        }
    }

    save(key: string, data: any) {
        if (!this.#storage) { return null; }

        this.#storage.setItem(key, JSON.stringify(data));
    }

    load<T>(key: string): T {
        if (!this.#storage) { return null; }

        const rawData = this.#storage.getItem(key);
        if (rawData) {
            return JSON.parse(rawData) as T;
        }
        return null;
    }

    #checkDataVersion() {
        if (!this.#storage) { return; }

        let localVersion: number = this.load<number>(this.#dataKey);
        if (!localVersion || localVersion !== this.#dataVersion) {
            localVersion = localVersion || 0;

            // migrate data one version at a time
            while (localVersion < this.#dataVersion) {
                this.#migrateData(localVersion);
                localVersion++;
            }

            this.save(this.#dataKey, localVersion);
        }
    }

    #migrateData(localVersion: number) {
        if (!this.#storage) { return; }

        // migrate to version 1, i.e nuke everything :]
        if (localVersion === 0) {
            this.#storage.clear();
        }
    }
}

export const Serializer = new StateSerializer();

export const bootstrapSerializer = function (window: Window) {
    Serializer.initialize(window);
}

