import { Template } from "../models/template";

export class TemplatesRegistry {
    constructor() {
    }

    registerTemplate(template: Template) {
        this[template.name] = template;
    }
}
