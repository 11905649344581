import { Application } from "@hotwired/stimulus";
import { BooksController } from "./books";
import { FilterController } from './filter';
import { SortController } from "./sort";
import { ViewsController } from "./views";

declare global {
    interface Window {
        Stimulus: Application;
    }
}

export function bootstrapControllers(window: Window) {
    window.Stimulus = Application.start();
    window.Stimulus.register("views", ViewsController);
    window.Stimulus.register("sort", SortController);
    window.Stimulus.register("filter", FilterController);
    window.Stimulus.register("books", BooksController);
}